import React from 'react'

function PitchData() {
  return (
	<div className="md:w-4/6 mx-auto px-2">
        <div className=" bg-surface w-full h-full rounded block py-20">
          <div className="w-4/5 mx-auto text-white">
            <p className="text-lg md:text-3xl text-secondary">
              The original idea<span className="text-base md:text-2xl"> - An app that lets users share their movie watchlist, reviews, and ratings with their friends. 
                                      It allows them to chat about movies, find out when and where tehy are playing, schedule an event, and purchase tickets.</span>
            </p>
            <div className="col-span-1 md:flex justify-center md:space-x-20 pt-10 pb-10">
              <img src="/pitch1.png" alt="Pitch 1" className="md:w-5/12 w-4/5 h-auto mx-auto"/>
			  <br className="md:hidden" />
              <img src="/pitch2.png" alt="Pitch 2" className="md:w-5/12 w-4/5 h-auto mx-auto"/>
            </div>
            <br />
            <p className="text-lg md:text-3xl text-secondary">Features List:</p>
            <ul className="list-disc ml-5 text-base md:text-2xl text-secondary">
              <li>
                Keep track of movies in watchlist
              </li>
              <li>Rate movies and write reviews</li>
              <li>
                See what movies your friends have watched and their ratings
              </li>
              <li>Chat with friends </li>
              <li>Find nearby theatres and get tickets</li>
              <li>Set up an event schedule</li>
            </ul>
          </div>
        </div>
      </div>
  )
}

export default PitchData