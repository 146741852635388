import React from "react";
import PitchFull from "./pitch/PitchFull";
import Pitch from "./pitch/Pitch";
import ShowAndTellOne from "./showAndTell1/ShowAndTellOne";
import ShowAndTellTwo from "./showAndTell2/ShowAndTellTwo";
import ShowAndTellOneFull from "./showAndTell1/ShowAndTellOneFull";
import ShowAndTellTwoFull from "./showAndTell2/ShowAndTellTwoFull";
import { Transition } from "@headlessui/react";

function Body() {
  const [pitch, setPitch] = React.useState(false);
  const [showAndTell1, setShowAndTell1] = React.useState(false);
  const [showAndTell2, setShowAndTell2] = React.useState(false);

  const togglePitch = () => {
    setPitch(!pitch);
  };

  const toggleShowAndTell1 = () => {
    setShowAndTell1(!showAndTell1);
  };

  const toggleShowAndTell2 = () => {
    setShowAndTell2(!showAndTell2);
  };

  return (
    <div>
      <Transition
        appear={true}
        show={pitch || showAndTell1 || showAndTell2}
        enter="transition-opacity duration-500 ease-linear"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500 ease-linear"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div onClick={togglePitch} className={pitch ? "block py-5" : "hidden"}>
          <PitchFull />
        </div>
        <div
          onClick={toggleShowAndTell1}
          className={showAndTell1 ? "block py-5" : "hidden"}>
          <ShowAndTellOneFull />
        </div>
        <div
          onClick={toggleShowAndTell2}
          className={showAndTell2 ? "block py-5" : "hidden"}>
          <ShowAndTellTwoFull />
        </div>
      </Transition>

      <Transition
        appear={true}
        show={!pitch && !showAndTell1 && !showAndTell2}
        enter="transition-opacity duration-500 ease-linear"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500 ease-linear"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div
          className={
            pitch || showAndTell1 || showAndTell2 ? "" : "flex flex-wrap md:w-4/5 w-11/12 mx-auto py-5"
          }>
          <div className="md:w-1/3 w-full rounded" onClick={togglePitch}>
            <Pitch full="false" />
          </div>
          <div className="md:w-1/3 w-full rounded" onClick={toggleShowAndTell1}>
            <ShowAndTellOne full="false" />
          </div>
          <div className="md:w-1/3 w-full rounded" onClick={toggleShowAndTell2}>
            <ShowAndTellTwo full="false" />
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default Body;
