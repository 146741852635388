import React from "react";
import ShowAndTellTwo from "./ShowAndTellTwo";
import ShowAndTellTwoData from "./ShowAndTellTwoData";

function ShowAndTellTwoFull() {
  return (
    <div className="md:flex md:w-4/5 w-11/12 py-20 bg-surface rounded shadow-sm mx-auto hover:shadow-md hover:shadow-surface hover:cursor-pointer">
      <ShowAndTellTwo full="true" />
      <ShowAndTellTwoData />
    </div>
  );
}

export default ShowAndTellTwoFull;
