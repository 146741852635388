import React from "react";

function Pitch(props) {
  const full = props.full === "true";

  return (
    <div
      className={
        full
          ? "bg-surface rounded shadow-sm w-full md:w-2/6 my-5 mx-auto px-10 py-5 transition-transform"
          : "bg-surface rounded shadow-sm my-5 mx-2 px-10 py-5 hover:shadow-md hover:shadow-surface hover:cursor-pointer"
      }>
      <h3 className="w-full my-4 text-4xl font-bold text-primary">Pitch</h3>
      {full ? (
        <p className="w-full my-4 text-sm font-bold text-primary">
          This is the pitch for the project. We will be discussing the project
          and what we plan to do.
        </p>
      ) : null}
      <h3 className="w-full my-4 text-sm font-bold text-primary">Video:</h3>
      <iframe
        className="w-full my-2 aspect-video"
        src="https://www.youtube.com/embed/yCKAy4Cv8Dc?si=4UuXvdswm6zVUR2Y"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
      <h3 className="text-sm font-bold text-primary w-full mt-16 mb-4">
        Slideshow:
      </h3>
      <iframe
        className="w-full aspect-video"
        src="https://docs.google.com/presentation/d/e/2PACX-1vTTiggWH23DLDhB57--lGZu8mv0JTYGAc6UucuHryeM-dkPEIFTm-WBg_N-MHApUuN9MYIzEYklXW2I/embed?start=false&loop=true&delayms=60000"
        frameborder="0"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"></iframe>
    </div>
  );
}

export default Pitch;
