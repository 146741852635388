import React from "react";

function ShowAndTellOneData() {
  return (
    <div className="md:w-4/6 mx-auto px-2">
      <div className=" bg-surface w-full h-full rounded block py-20">
        <iframe
          className="mx-auto rounded w-4/5 aspect-video"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FNrDCBkqAmGrPplgRmPgurC%2FShow-and-Tell-1%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DPLO6i5DXKsZV507Z-1"
          allowfullscreen></iframe>
          <br />
        <div className="w-4/5 mx-auto">
          <div className="w-full md:flex">
            <img
              src="/mvvm1.png"
              alt="mvvm"
              className="mx-auto rounded md:w-1/3"
            />
            <br className="md:hidden" />
            <img
              src="/mvvm2.png"
              alt="mvvm"
              className="mx-auto rounded md:w-1/3"
            />
            <br className="md:hidden" />
          </div>
          <br className="hidden md:block" />
          <div className="w-full md:flex">
            <img
              src="/mvvm3.png"
              alt="mvvm"
              className="mx-auto rounded md:w-1/3"
            />
            <br className="md:hidden" />
            <img
              src="/mvvm4.png"
              alt="mvvm"
              className="mx-auto rounded md:w-1/3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowAndTellOneData;
