import React from "react";
import Pitch from "./Pitch";
import PitchData from "./PitchData";

function PitchFull() {
  return (
    <div className="md:flex  md:w-4/5 w-11/12 py-20 bg-surface rounded shadow-sm mx-auto hover:shadow-md hover:shadow-surface hover:cursor-pointer">
      <Pitch full="true" />
      <PitchData />
    </div>
  );
}

export default PitchFull;
