import React from "react";
import ReactDOM from "react-dom/client";
import Members from "./Members";
import "./style.css";
import ProjectStatement from "./ProjectStatement";
import Heading from "./Heading";
import Body from "./Body";
import Download from "./Download";
import Code from "./Code";
import Final from "./Final";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="flex flex-col min-h-screen">
      <Heading />
      <div className="grow">
        <ProjectStatement />
        <Download />
        <Code />
        <Members />
        <Body />
        <Final />
      </div>
    </div>
  </React.StrictMode>
);
