import React from "react";

function ShowAndTellOne(props) {
  const full = props.full === "true";

  return (
    <div
      className={
        full
          ? "bg-surface rounded shadow-sm w-full md:w-2/6 my-5 mx-auto px-10 py-5 hover:cursor-pointer"
          : "bg-surface rounded shadow-sm mx-2 my-5 px-10 py-5 hover:shadow-md hover:shadow-surface hover:cursor-pointer"
      }>
      <h3 className="w-full my-4 text-4xl font-bold text-primary">
        Show and Tell 1
      </h3>
      {full ? (
        <p className="w-full my-4 text-sm font-bold text-primary">
          This is the first show and tell for the project. We will be discussing
          the project and the progress we have made so far.
        </p>
      ) : null}
      <h3 className="w-full my-4 text-sm font-bold text-primary">Video:</h3>
      <iframe
        className="w-full my-2 aspect-video"
        src="https://www.youtube.com/embed/CgDsn1gvaQ4?si=KSjH-3kO0XGZKqAO"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
      <h3 className="text-sm font-bold text-primary w-full mt-16 mb-4">
        Slideshow:
      </h3>
      <iframe
        className="w-full aspect-video"
        src="https://docs.google.com/presentation/d/e/2PACX-1vRIj3fACMm-M_ovRjHjkEUHbaIgpZdM5AwwKQwzdE7Z1RyZ730QRoOZGHNBCo95wg/embed?start=false&loop=false&delayms=60000"
        frameborder="0"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"></iframe>
      {full ? (
        <div className="my-5 mx-2 w-auto">
          <h2 className="text-2xl text-secondary">
            <span className="text-3xl">TMDB - The Movie Database</span> - 
            This is the API we decided to use as the base of our application.
            It has a lot of information about movies and TV shows, and it is 
            free to use. We will be using this API to get information about
            movies and TV shows, as well as images and videos. 
          </h2>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ShowAndTellOne;
