import React from "react";

function Code() {
  return (
    <div className="mx-auto md:w-4/5 w-11/12 mb-20 mt-10">
      <h2 className="my-2 text-4xl font-bold text-primary">Code:</h2>
      <div className="w-full text-white text-2xl">
        <a href="https://github.com/CMPT-362-Group3/Cinebon">
          Click here to go to the Github repository containing our code
        </a>
      </div>
    </div>
  );
}

export default Code;
