import React from "react";

function ShowAndTellTwo(props) {
  const full = props.full === "true";
  return (
    <div
      className={
        full
          ? "bg-surface rounded shadow-sm w-full md:w-2/6 my-5 mx-auto px-10 py-5 hover:cursor-pointer"
          : "bg-surface rounded shadow-sm my-5 mx-2 px-10 py-5 hover:shadow-md hover:shadow-surface hover:cursor-pointer"
      }>
      <h3 className="w-full my-4 text-4xl font-bold text-primary">
        Show and Tell 2
      </h3>
      {full ? (
        <p className="w-full my-4 text-sm font-bold text-primary">
          This is the second show and tell for the project. We will be
          discussing the project and the progress we have made so far.
        </p>
      ) : null}
      <h3 className="w-full my-4 text-sm font-bold text-primary">Video:</h3>
      <iframe
        className="w-full my-2 aspect-video"
        src="https://www.youtube.com/embed/ahV56wr5A9w?si=JgJDPVm1FbJKWkNu"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
      <h3 className="text-sm font-bold text-primary w-full mt-16 mb-4">
        Slideshow:
      </h3>
      <iframe
        className="w-full aspect-video"
        src="https://docs.google.com/presentation/d/e/2PACX-1vRqiEU6NQImvnIn39PcTEUlTrmfRoWprLmSTXQmivbPvbvR_RaTcvqUkpt_N-6UPg/embed?start=false&loop=false&delayms=60000"
        frameborder="0"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"></iframe>
    </div>
  );
}

export default ShowAndTellTwo;
