import React from "react";

function Members() {
  return (
    <div className="my-20 md:w-4/5 w-11/12 mx-auto">
      <h2 className="my-2 text-4xl font-bold w-full text-primary">Members:</h2>
      <div className="w-full text-white text-2xl">
        <p className="mb-10">
          Welcome to our blog! We are Group 3, a dynamic team of 4 Computing
          Science students from Simon Fraser University. This webpage shows our
          journey into developing our final project, a fully functioning Android
          application for the class of CMPT 362 - Mobile Applications
          Programming and Design.
        </p>
      </div>

      <div className="mx-auto text-xs md:text-base">
        <div div className="grid grid-cols-4 gap-4 rounded-2xl mt-6 font-mono">
          <div className="text-muted text-center">Name</div>
          <div className="text-muted text-center">Student Number</div>
          <div className="text-muted text-center">Email</div>
          <div className="text-muted text-center">Links</div>

          <div className="bg-blue text-blue text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://shabbiryusufali.ca"
              target="_blank"
              rel="noreferrer"
              className="underline">
              Shabbir Yusufali
            </a>
          </div>
          <div className="bg-blue text-blue text-center rounded-2xl md:px-8 py-8">
            301424687
          </div>
          <div className="bg-blue text-blue text-center rounded-2xl md:px-8 py-8">
            <a href="mailto:sya167@sfu.ca" className="underline">
              sya167@sfu.ca
            </a>
          </div>
          <div className="bg-blue text-blue text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://github.com/shabbiryusufali"
              target="_blank"
              rel="noreferrer"
              className="underline">
              GitHub
            </a>
          </div>

          <div className="bg-teal text-teal text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://manku.dev"
              target="_blank"
              rel="noreferrer"
              className="underline">
              Tanish Manku
            </a>
          </div>
          <div className="bg-teal text-teal text-center rounded-2xl md:px-8 py-8">
            301386155
          </div>
          <div className="bg-teal text-teal text-center rounded-2xl md:px-8 py-8">
            <a href="mailto:tanishm@sfu.ca" className="underline">
              tanishm@sfu.ca
            </a>
          </div>
          <div className="bg-teal text-teal text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://github.com/tanish2k09"
              target="_blank"
              rel="noreferrer"
              className="underline">
              GitHub
            </a>
          </div>

          <div className="bg-orange text-orange text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://www.linkedin.com/in/darrick-gunawan/"
              target="_blank"
              rel="noreferrer"
              className="underline">
              Darrick Gunawan
            </a>
          </div>
          <div className="bg-orange text-orange text-center rounded-2xl md:px-8 py-8">
            301460603
          </div>
          <div className="bg-orange text-orange text-center rounded-2xl md:px-8 py-8">
            <a href="mailto:dga48@sfu.ca" className="underline">
              dga48@sfu.ca
            </a>
          </div>
          <div className="bg-orange text-orange text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://github.com/Darrickg"
              target="_blank"
              rel="noreferrer"
              className="underline">
              GitHub
            </a>
          </div>

          <div className="bg-pink text-pink text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://www.linkedin.com/in/maisha-supritee-chowdhury/"
              target="_blank"
              rel="noreferrer"
              className="underline">
              Maisha Supritee Chowdhury
            </a>
          </div>
          <div className="bg-pink text-pink text-center rounded-2xl md:px-8 py-8">
            301401482
          </div>
          <div className="bg-pink text-pink text-center rounded-2xl md:px-8 py-8">
            <a href="mailto:mschowdh@sfu.ca" className="underline">
              mschowdh@sfu.ca
            </a>
          </div>
          <div className="bg-pink text-pink text-center rounded-2xl md:px-8 py-8">
            <a
              href="https://github.com/maishaSupritee"
              target="_blank"
              rel="noreferrer"
              className="underline">
              GitHub
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Members;
