import React from "react";

function ShowAndTellTwoData() {
  return (
    <div className="md:w-4/6 mx-auto px-2">
      <div className=" bg-surface w-full h-full rounded block py-20">
        <p className="text-2xl text-secondary">
          By now we have added our biggest features: Sign up/in authenitcation and settings screen. At this point in time, we already know most if not all the features our app will have, and we created a thread diagram to show how all our components interact with each other.
          <img src="/threadDiagram.svg" alt="threadDiagram" className="w-auto h-3/4" />
          Our app's main screen is the dashboard, which has 3 components: Movies, Lists, and Profile. Even though we ended up changing a few things in the future, the overall structure of the thread diagram remains the same.
        </p>

        <br />

        <div className="col-span-1 flex justify-center space-x-5 pt-10 pb-10">
          <img src="/sat2_1.png" alt="signUp" className="w-auto h-1/3" />
          <p className="text-2xl text-secondary">
            This image shows our signup page. Upon signing up, user data will be created, and user will have access to our app!
            <img src="/sat2_3.png" alt="firestore" className="w-auto h-1/3 pt-10 pb-10" />
            Pictured above is the user data in Firestore. Please watch our video to get a full demonstration of the features!
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShowAndTellTwoData;
