import React from "react";
import ShowAndTellOne from "./ShowAndTellOne";
import ShowAndTellOneData from "./ShowAndTellOneData";

function ShowAndTellOneFull() {
  return (
    <div className="md:flex md:w-4/5 w-11/12 py-20 bg-surface rounded shadow-sm mx-auto hover:shadow-md hover:shadow-surface hover:cursor-pointer">
      <ShowAndTellOne full="true" />
      <ShowAndTellOneData />
    </div>
  );
}

export default ShowAndTellOneFull;
