import React from "react";

function Final() {
  return (
    <div className="md:w-4/5 w-11/12 py-20 px-20 bg-surface rounded shadow-sm mx-auto">
      <h3 className="w-full my-4 text-4xl font-bold text-primary">
        The Final Product.
      </h3>

      <h3 className="w-full md:w-4/5 mx-auto my-4 text-sm font-bold text-primary">Video:</h3>
      <iframe
        className="w-full md:w-4/5 mx-auto aspect-video my-2"
        src="https://www.youtube.com/embed/vZ53S-FjCyE?si=i5Bgxs-VqLgoP6Kx"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>

      <h3 className="w-full md:w-4/5 mx-auto text-sm font-bold text-primary mt-16 mb-4">Slideshow:</h3>
      <iframe
        className="w-full md:w-4/5 mx-auto aspect-video my-2"
        src="https://docs.google.com/presentation/d/e/2PACX-1vQ7SHaF-rx9XsYLaRGX-PzY4y_9cL-qbtaZ-u1MTDotYxSjxBy7A1foQEtZoXYshmZW2LTidUcJk9t9/embed?start=false&loop=false&delayms=60000"
        frameborder="0"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"></iframe>
      <br />

      <p className="text-3xl text-secondary">
        After weeks of development, and many different trips back to the drawing
        board, we finally finished our app.
      </p>

      <div className="col-span-1 flex justify-center space-x-20 pt-10 pb-10">
        <div>
          <p className="text-3xl text-secondary">Features List:</p>
          <ul className="list-disc ml-5 text-2xl text-secondary">
            <li>User authentication flow</li>
            <li>Movie information lookup</li>
            <li>Adding friends feature</li>
            <li>Instant-messaging chat</li>
            <li>Search bar functionality</li>
            <li>Making and editing watchlists</li>
          </ul>
        </div>

        <div>
          <p className="text-3xl text-secondary">Technologies Used:</p>
          <ul className="list-disc ml-5 text-2xl text-secondary">
            <li>Firebase Authentication</li>
            <li>Firestore Database</li>
            <li>TMDB API</li>
            <li>Jetpack Compose</li>
            <li>Of course, Kotlin and all its useful features</li>
          </ul>
        </div>
      </div>

      <p className="text-3xl text-secondary">Our final MVVM diagram:</p>
      <img
        src="/threadDiagramFinal.svg"
        alt="threadDiagram"
        className="w-auto h-half"
      />
      <p className="text-2xl text-secondary">
        As you can see, there are some changes from when this was last shown in
        Show and Tell 2. This was because some features took more components
        than we thought they would.
      </p>

      <h3 className="w-full my-4 text-4xl font-bold text-primary">
        Division of Labour
      </h3>
      <div className="w-full mx-auto pb-10 pt-5">
        <div className="md:flex w-full">
          <div className="w-full md:w-5/12 mx-auto">
            <p className="text-3xl text-secondary">Darrick:</p>
            <ul className="list-disc ml-5 text-2xl text-secondary">
              <li>UI: SignUp, Profile, Settings</li>
              <li>Data Entity for movie</li>
              <li>Implement new chat functionality</li>
              <li>Thread Diagram</li>
              <li>Settings page implementation</li>
              <li>WatchList implementation</li>
              <li>Secondary website developer</li>
              <li>Voiceover for Show and Tell 2</li>
            </ul>
          </div>

          <br className="md:hidden block" />

          <div className="w-full md:w-5/12 mx-auto">
            <p className="text-3xl text-secondary">Maisha:</p>
            <ul className="list-disc ml-5 text-2xl text-secondary">
              <li>Search Bar Implementation</li>
              <li>Friends implementation</li>
              <li>UI: Socials, Profiles, Individual Chat, Chat List</li>
              <li>UI Mockup using Figma</li>
              <li>MVVM Diagrams</li>
              <li>All Powerpoint Slides</li>
              <li>Show and Tell 1 Video</li>
            </ul>
          </div>
        </div>

        <br />

        <div className="md:flex w-full ">
          <div className="w-full md:w-5/12 mx-auto">
            <p className="text-3xl text-secondary">Shabbir:</p>
            <ul className="list-disc ml-5 text-2xl text-secondary">
              <li>Administrative tasks</li>
              <li>Primary website developer</li>
              <li>Some friends logic and code</li>
              <li>
                User Auth: Backend Logic + Adding Forgot Password + Sign Out
              </li>
              <li>Code refactoring</li>
              <li>Video editing + Voiceover for Show and Tell 2</li>
              <li>
                Helping with Feature flow/ideation and supporting members in
                their code development
              </li>
            </ul>
          </div>

          <br className="md:hidden block" />

          <div className="w-full md:w-5/12 mx-auto">
            <p className="text-3xl text-secondary">Tanish:</p>
            <ul className="list-disc ml-5 text-2xl text-secondary">
              <li>Dashboard Navigation implementation</li>
              <li>
                Setup TMDB API and implementing all logic related to Movies
              </li>
              <li>
                All chat related implementation & WatchList implementation
              </li>
              <li>UI: Login, Movies + Threaded Diagram</li>
              <li>
                Misc coding, bug fixes, finishing incomplete things, cleanup
                code
              </li>
              <li>
                Helping with Feature flow/ideation and supporting members in
                their code development
              </li>
              <li>Initializing project in GitHub with Material Theme</li>
            </ul>
          </div>
        </div>
      </div>

      <h3 className="w-full my-4 text-4xl font-bold text-primary">
        THANK YOU!
      </h3>
    </div>
  );
}
export default Final;
