import React from "react";

function Heading() {
  return (
    <div>
      <img src="/cinebon.png" className="mx-auto w-24 h-24 my-12" />
      <h1 className="text-5xl text-center mb-5 font-bold text-white">
        Cinébon
      </h1>
      <h2 className="text-2xl text-center mb-20 text-white">
        a movie cataloguing app
      </h2>
    </div>
  );
}

export default Heading;
