import React from "react";

function ProjectStatement() {
  return (
    <div className="mx-auto md:w-4/5 w-11/12 mb-20 mt-10">
      <h2 className="my-2 text-4xl font-bold text-primary">
        Project Statement:
      </h2>
      <div className="w-full text-white text-2xl">
        Dive into the world of entertainment discovery with our project, offering users the latest insights into popular movies and TV shows. 
        Craft your own 'Watchlist,' stay updated on the newest releases, and chat with fellow enthusiasts for lively discussions about beloved media. 
        Our platform is a hub for curated movie information, creating a community where users can share their passion and knowledge for the cinematic arts.
      </div>
    </div>
  );
}

export default ProjectStatement;
