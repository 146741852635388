import React from "react";

function Download() {
  return (
    <div className="mx-auto md:w-4/5 w-11/12 mb-20 mt-10">
      <h2 className="my-2 text-4xl font-bold text-primary">Download:</h2>
      <div className="w-full text-white text-2xl">
        <a href="/Cinebon.apk" download="Cinebon.apk">
          Click here to download the apk file
        </a>
        <br />
        <a href="/CMPT362_Group3_Project.zip" download="CMPT362_Group3_Project.zip">
          Click here to download the zip file
        </a>
      </div>
    </div>
  );
}

export default Download;
